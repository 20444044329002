'use client'
import { makeStore } from '@/redux/store';
import { PlateController } from '@udecode/plate-common/react';
import { useRef } from 'react'
import { Provider } from 'react-redux'
import { persistStore } from "redux-persist";
import { PersistGate } from 'redux-persist/integration/react'
import Modal from "react-modal"

export default function StoreProvider({ children }) {
  const storeRef = useRef()
  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current =   makeStore()
  }
  Modal.setAppElement('body');

  return <Provider store={storeRef.current}>
    <PersistGate loading={null} persistor={storeRef.current.__persistor}>
      <PlateController>

        {children}
      </PlateController>
      </PersistGate>
    </Provider>
}